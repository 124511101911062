import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Em, Strong, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "50px 0 50px 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 1rem 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px",
			"height": "auto"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"height": "auto"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "Tauentzienstraße 9-12, 10789 Berlin, Germany"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "--light",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"display": "none",
			"children": "Tauentzienstraße 9-12, 10789 Berlin, Germany"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"height": "auto"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "+4930688381768"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"href": "0382785343",
			"display": "none",
			"children": "+4930688381768"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "#f5eceb",
			"href": "solenixa.com",
			"children": "info@solenixa.com",
			"display": "none"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"height": "auto"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "Mo-Sa: 09:00 - 21:00 Uhr"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "--light",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"display": "none",
			"children": "Пн-Сб: 09:00 - 21:00"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "--light",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": <>
				{" "}Нд: 10:00 - 20:00
			</>,
			"display": "none"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-transform": "uppercase",
			"children": "PowerFlex Gym"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
		</Box>
		<Text {...override("text6")} />
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;