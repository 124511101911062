import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, List, Em, Section, Strong, Span } from "@quarkly/widgets";
const defaultProps = {
	"background-color": "--dark",
	"text-align": "center",
	"padding": "32px 0",
	"quarkly-title": "Footer-1"
};
const overrides = {
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Startseite"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/about",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Про нас",
			"display": "none"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/services",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Dienstleistungen"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Mga contact",
			"display": "none"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "mailto:hello@company.com",
			"text-decoration-line": "none",
			"variant": "--base",
			"color": "--grey",
			"hover-color": "--primary",
			"font": "--lead",
			"children": <>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--headline2"
				>
					<Span
						font-weight="normal"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="--headline3"
					>
						Besuchen Sie uns noch heute, um zu sehen, welchen Unterschied PowerFlex Gym auf Ihrem Weg zur Fitness machen kann. Für weitere Informationen kontaktieren Sie uns bitte:
					</Span>
					<br />
				</Span>
				<br />
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--headline3"
				>
					Tauentzienstraße 9-12, 10789{" "}
					<br />
					Berlin, Germany
					<br />
					<br />
					+4930688381768
				</Em>
			</>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</List>
		<Link {...override("link4")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;